import g from './globals';
import cf from './contact';

if (g.elementInDom('.select-form')) {
    const selectForm = document.querySelector('#select-form'),
        formElements = document.querySelectorAll('.form-element');

    // Initialize State population for first form
    cf()

    document.addEventListener('input', (event) => {
        if (event.target.id !== 'select-form') return;

        // Get the selected value from the dropdown
        const selectedValue = selectForm.value;

        // Remove 'form-active' from all form elements
        formElements.forEach((obj) => {
            obj.classList.remove('form-active');
        });

        // Add 'form-active' to the matching form element
        formElements.forEach((obj) => {
            if (obj.getAttribute('data-form') === selectedValue) {
                obj.classList.add('form-active');
                cf() // Initiate new contact form instance
            }
        });
    });
}